import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w02TA06Wildtiere = () => (
  <Layout>
    <SEO title="w02TA06Wildtiere" />

    <h1 id="ueberschrift">Tiere im Alltag</h1>
    <h2>Der Umgang mit "Wildtieren" &emsp;  ©&nbsp;2002</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w02TA06Wildtiere&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        "Die Schlange sticht nicht ungereizt."<br />
        <div style={{fontSize: "0.75em"}}>Friedrich Schiller (1759 - 1805) aus Wilhelm Tell</div>
      </p>

    <p>---</p>

      <p>
        Ob Spinnen, Schlangen, Ratten, Wildschweine oder andere ungewöhnliche Wildtiere als Mitbewohner: erzählen Sie 
        bei einem gemütlichen Beisammensein von Ihren Erlebnissen, Sorgen und Freuden mit Ihrem besonderen Hausgenossen.
        Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen
        oder einfach bei lustigen und interessanten Anekdoten die Zeit genießen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz pro Teilnehmer + 1 Sitzplatz extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w02TA06Wildtiere
